import React, { useState, useEffect } from 'react';
import api from "../../services/api";

import { Link } from "react-router-dom";
import { FaYoutube, FaInstagram, FaWhatsapp, FaPhone, FaBoxOpen, FaUserAlt, FaSearch, FaShoppingCart } from "react-icons/fa";
import { BsChatDots } from "react-icons/bs";
import imgLogo from '../../imgs/logos/01.png';

import { useNavigate } from "react-router-dom";

import { ContainerHeader } from "./style.js";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { verifyLogin } from "./../../utils/verifyLogin";
import { useCart } from "./../../utils/CartContext";

const Header = ({ searchText }) => {

  const [groupList, setGroupList] = useState([]);
  const [textProduct, setTextProduct] = useState(searchText);

  const [cardLenght, setCardLenght] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    api
      .get("get_all_groups")
      .then((response) => {
        setGroupList(response.data);
      })
      .catch((err) => {
        console.log(err);
      });

    let prodList = [];

    try {
      const storedData = localStorage.getItem('productCart');
      if (storedData) {
        prodList = JSON.parse(storedData);
      }
    } catch (error) {
      console.error('Error parsing productCart data:', error);
    }

    if (prodList.length > 0) {
      setCardLenght(prodList.length);
    }
  }, []);


  const productCartUpdated = new Event('productCartUpdated');

  useEffect(() => {
    const handleProductCartUpdate = () => {
      const newProdList = JSON.parse(localStorage.getItem('productCart'));
      setCardLenght(newProdList ? newProdList.length : 0);
    };

    window.addEventListener('productCartUpdated', handleProductCartUpdate);

    return () => {
      window.removeEventListener('productCartUpdated', handleProductCartUpdate);
    };
  }, []);


  const searchProducts = () => {
    if (textProduct) {
      navigate(`/buscar/${textProduct.replace(/\//g, ' | ')}`);
    } else {
      navigate(`/`);
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchProducts();
    }
  };

  const { cartLength } = useCart();

  return (
    <>
      <ContainerHeader>
        <Container>
          <Row className="social-media">
            <Col sm={2} className="medias">
              <Link to="https://www.youtube.com/@BelicaMilitar" target="_blank"><span><FaYoutube /></span></Link>
              <Link to="https://www.instagram.com/belicamilitar/" target="_blank"><span><FaInstagram /></span></Link>
            </Col>
            <Col sm={10} className="contact-list">
              <Link to=""><BsChatDots style={{ "fontSize": "14px" }} /> Fale Conosco</Link>
              <Link to=""><FaPhone style={{ "fontSize": "12px" }} /> Telefone: (47) 3333-1759</Link>
              <Link to=""><FaWhatsapp style={{ "fontSize": "16px" }} /> Whatsapp: (47) 99901-2437</Link>
            </Col>
            <Col sm={10} className="options-list-mobile">
              <Link to="/cart"><FaShoppingCart style={{ "fontSize": "25px" }} /></Link>
              <Link to="/orders"><FaBoxOpen style={{ "fontSize": "25px" }} /></Link>
              <Link to="/account"><FaUserAlt style={{ "fontSize": "23px" }} /></Link>
            </Col>
          </Row>
          <Row className="menu-container">
            <Col sm={12}>
              <Row>
                <Col sm={3} id="logoTop">
                  <Link to="/">
                    <img src={imgLogo} />
                  </Link>
                </Col>
                <Col sm={9}>
                  <Row>
                    <Col sm={6}>
                      <p>{verifyLogin() ? 'Bem-vindo e boas compras!' : 'Bem-vindo, identifique-se para fazer pedidos'}</p>
                    </Col>
                    <Col sm={6}>
                      <Row className="accountOptions">
                        <Col sm={6}>
                          <Link to="/orders"><FaBoxOpen style={{ "fontSize": "17px" }} /> Meus Pedidos</Link>
                        </Col>
                        <Col sm={6}>
                          <Link to="/account"><FaUserAlt style={{ "fontSize": "14px" }} /> {verifyLogin() ? 'Minha Conta' : 'Entrar / Cadastrar'}</Link>
                        </Col>
                      </Row>
                    </Col>

                    <Col sm={8} className="search">
                      <input
                        type="text"
                        value={textProduct}
                        placeholder="Digite o que você procura"
                        autocomplete="off"
                        onChange={e => setTextProduct(e.target.value)}
                        onKeyDown={handleKeyDown}
                      />
                      <FaSearch onClick={searchProducts} />
                    </Col>

                    <Col sm={4}>
                      <div className="cartMenu">
                        <Link to="/cart"><FaShoppingCart style={{ "fontSize": "16px" }} /> Carrinho ({cartLength})</Link>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Row className="menu-groups">
              <Col sm={12}>
                <Navbar bg="light" expand="lg" className="flex-wrap">
                  <Container fluid>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                      <Nav className="me-auto my-2 my-lg-0 navbar-nav flex-wrap" navbarScroll>
                        {
                          groupList
                            .filter((group) => !group.ID_DM013_PAI)
                            .map((group, index) => {
                              const subGroups = groupList.filter(
                                (subGroup) => subGroup.ID_DM013_PAI === group.ID_DM013
                              );

                              return subGroups.length > 0 ? (
                                <NavDropdown title={group.NM_GRUPO} className="item-menu">
                                  {subGroups.map((subGroup, index) => (
                                    <NavDropdown.Item
                                      as={Link}
                                      to={`/group/${subGroup.ID_DM013}/${subGroup.NM_GRUPO.replace(/\//g, ' | ')}`}
                                      className="dropdown-item"
                                    >
                                      {subGroup.NM_GRUPO}
                                    </NavDropdown.Item>
                                  ))}
                                </NavDropdown>
                              ) :
                                <Nav.Link as={Link} to={`/group/${group.ID_DM013}/${group.NM_GRUPO}`} className="item-menu">{group.NM_GRUPO}</Nav.Link>
                            })
                        }

                      </Nav>
                    </Navbar.Collapse>
                  </Container>
                </Navbar>
              </Col>
            </Row>
          </Row>
        </Container>
      </ContainerHeader>
    </>
  );
}

export default Header;
