import React, { useState, useEffect, useRef } from 'react';
import api from "../../services/api";
import Header from "../../components/Header";
import ProductCard from "../../components/ProductCard";
import Footer from "../../components/Footer";

import { useParams, useNavigate } from "react-router-dom";

import HistoryPages from '../../components/HistoryPages';

import {ContainerSearchProduct, Title} from "./style.js";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const SearchProduct = (  ) => {

  
  const [productList, setProductList] = useState([]);
  const { id } = useParams();
  const { search } = useParams();
  
  useEffect(() => {
    
    const dataFilter = { 
      "ID_DM005": "",
      "ID_DM013": "",
      "ID_DM013_PAI": id,
      "NM_PRODUTO": "",
      "IN_SITUACAO": "A",
      "CD_PRODUTO": "",
      "IN_DESTAQUE": "",
      "ORDER_BY": true
    }
    
    api
    .post("filter_products", dataFilter)
    .then((response) => {
      setProductList(response.data)
    })
    .catch((err) => {
      console.log(err)
    });

    document.title = `Bélica Militar - ${search}`  
  }, [id, search]);  


  return (
    <>
      
      <Header/>
      <ContainerSearchProduct>
        <Container className="conShadown">
          <HistoryPages />
          <Title>Filtro por grupo: {search}</Title>
          <br/>
          <Row>
            {productList.map((product, index) => (
                <ProductCard idDM005={product.ID_DM005} name={product.NM_PRODUTO} description={product.NM_PRODUTO} price={product.VL_UNITARIO} promo={product.VL_UNITARIO_PROMOCIONAL} imgsJSON={product.JS_IMAGEM} />
              ))}
          </Row>
        </Container>
      </ContainerSearchProduct>
      <Footer/>
    </>
  );
}

export default SearchProduct;
